import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { Perks, PerkItem, PerkImage } from ".";
import { Slogan, Anchor, ContactsContainer } from "../components/Banner";
import { Contacts } from "../components/Contacts";
import { List, ListItem } from "../components/Items";
import { Layout } from "../components/Layout";
import { breakpoints } from "../styledResponsive";
import {
    ImageDesktopContainer,
    Content,
    ImageMobileContainer,
    Text,
    ImageLaptopContainer,
} from "./vale-dos-vinhedos";

const Banner = styled.div`
    width: 100%;
    position: relative;
`;

const TextBig = styled.p`
    padding: 40px 0;
    font-size: 24px;
    font-weight: 100;
`;

const Intro = styled.div`
    margin: auto;
    width: 90%;
    ${breakpoints("width", ["90%", "90%", "450px", "450px"])};
    text-align: center;
`;

const PayPerUse = () => (
    <Layout>
        <Banner>
            <ContactsContainer>
                <Contacts />
            </ContactsContainer>
            <ImageDesktopContainer>
                <StaticImage
                    src="../images/pay-per-use/banner-desktop.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageDesktopContainer>
            <ImageMobileContainer>
                <StaticImage
                    src="../images/pay-per-use/banner-mobile.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageMobileContainer>
            <ImageLaptopContainer>
                <StaticImage
                    src="../images/pay-per-use/banner-laptop.jpg"
                    alt="Vale dos Vinhedos"
                />
            </ImageLaptopContainer>
            <Content>
                <Slogan>
                    Serviços <br /> Pay Per Use
                </Slogan>
                <Anchor href="#main-content">
                    <StaticImage
                        src="../images/icons/arrow-down.png"
                        alt="Ver conteúdo"
                        style={{
                            maxWidth: "100%",
                            display: "block",
                        }}
                    />
                </Anchor>
            </Content>
        </Banner>

        <Intro id="main-content">
            <TextBig>
                Parceria com o Hotel Laghetto Riserva Vale dos Vinhedos,
                empreendimento localizado junto ao condomínio residencial
                Terroir, irá disponibilizar a opção de serviços como:
            </TextBig>
            <List>
                <ListItem>/ Camareira</ListItem>
                <ListItem>/ Café da Manhã</ListItem>
                <ListItem>/ Lavanderia</ListItem>
                <ListItem>/ Jantar</ListItem>
            </List>
        </Intro>

        <Perks>
            <PerkItem>
                <PerkImage>
                    <StaticImage
                        src="../images/pay-per-use/img-1.jpg"
                        alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                    />
                </PerkImage>
            </PerkItem>
            <PerkItem>
                <PerkImage>
                    <StaticImage
                        src="../images/pay-per-use/img-2.jpg"
                        alt="Infraestrutura de segurança com condomínio
totalmente murado e portaria 24 horas"
                    />
                </PerkImage>
            </PerkItem>
        </Perks>
    </Layout>
);

export default PayPerUse;
